@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth !important;
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

/******* View all home designs *********/
.button_all_home {
  border-radius: 1rem;
  background-color: #fde047;
  color: #000000;
  font-size: 1rem;
  overflow: hidden;
  padding: 0.5rem 1.5rem;
  position: relative;
  transition: 0.2s transform ease-in-out;
  z-index: 0;
}

.button_all_home::after {
  background-color: #893f8a;
  border-radius: 1rem;
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-100%, 0) rotate(10deg);
  transform-origin: top left;
  transition: 0.2s transform ease-out;
  will-change: transform;
  z-index: -1;
}

.button_all_home:hover::after {
  transform: translate(0, 0);
}

.button_all_home:hover {
  color: rgb(255, 255, 255);
  /* transform: scale(1.05); */
  will-change: transform;
}

/******* End View all home designs *********/

/************ ICON HOVER **********/
#icon-ig {
  fill: rgb(255, 255, 255);
  transition: all 0.3s ease;
  display: inline-block;
  -webkit-transition: fill 0.4s ease;
  -moz-transition: fill 0.4s ease;
  -o-transition: fill 0.4s ease;
  transition: fill 0.4s ease;
}

#icon-ig-follow {
  fill: rgb(0, 0, 0);
  transition: all 0.3s ease;
  display: inline-block;
  -webkit-transition: fill 0.4s ease;
  -moz-transition: fill 0.4s ease;
  -o-transition: fill 0.4s ease;
  transition: fill 0.4s ease;
}

#icon-ig:hover {
  fill: url(#gradient);
}

#icon-ig-follow:hover {
  fill: url(#gradient);
}

stop {
  transition: 0.4s ease;
}

#icon-ig:hover stop:first-child {
  stop-color: #fbad50;
}

#icon-ig:hover stop:nth-child(2) {
  stop-color: #fccc63;
}

#icon-ig:hover stop:nth-child(3) {
  stop-color: #bc2a8d;
}

#icon-ig:hover stop:nth-child(4) {
  stop-color: #8a3ab9;
}

#icon-ig-follow:hover stop:first-child {
  stop-color: #fbad50;
}

#icon-ig-follow:hover stop:nth-child(2) {
  stop-color: #fccc63;
}

#icon-ig-follow:hover stop:nth-child(3) {
  stop-color: #bc2a8d;
}

#icon-ig-follow:hover stop:nth-child(4) {
  stop-color: #8a3ab9;
}
/************ END ICON HOVER ***********/

/************ A TAG HOVER ***********/
.nav-tag {
  display: inline-block;
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  padding-bottom: 4px;
  letter-spacing: 1px;
}

.nav-tag:after {
  content: "";
  position: absolute;
  height: 2px;
  background-color: #cd76cf;
  width: 0;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) all;
  transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) all;
}

.nav-tag:hover {
  color: #cd76cf;
}

.nav-tag:hover:after {
  width: 100%;
}

.img-circle {
  border-radius: 50%;
  background-color: white;
  padding: 5px;
  display: flex;
  align-items: center;
  /* width: 100px;
  height: 100px; */
  /* width: 100px;
  height: 100px; */
}

li::marker {
  color: red;
  /* font-size: 1.5em; */
}

.bg-service {
  height: 100vh;
}

/************ NAME BLOG HOVER ***********/

.line:hover {
  color: #9a4d90;
}

.note {
  margin-top: 30px;
  color: #fff;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
}
article.card {
  height: 350px;
  /* border-radius: 3px; */
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  border: 3px solid#893f8a;
  border-radius: 3px;
}
article.card .thumb {
  width: auto;
  /* height: 260px; */
  height: 100%;
  /* background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/397014/new-york-city.png")
    no-repeat center; */
  background-size: cover;
  border-radius: 3px;
}
article.card .infos {
  width: 100%;
  /* height: 350px; */
  /* position: absolute; */
  /* top: -20%; */
  /* text-align: left; */
  /* padding: 0px 10px; */
  /* background: #fff; */
  /* transition: 0.4s 0.15s cubic-bezier(0.17, 0.67, 0.5, 1.03); */
  /* opacity: 0.9; */
}

.mySwiperVideo {
  --swiper-navigation-size: 34px;
  --swiper-navigation-color: #fff;
  --swiper-navigation-top-offset: 45%;
}
